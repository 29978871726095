module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1180,"wrapperStyle":"width:100%;","linkImagesToOriginal":false,"quality":100,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TSV Zorneding 1920 e.V. - Abteilung Fussball","short_name":"TSV Zorneding 1920 e.V. - Abteilung Fussball","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"/home/runner/work/tsv-zorneding-fussball/tsv-zorneding-fussball/plugins/gatsby-theme-tsv-zorneding/img/logo-512x512.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5be9f8ae480b4e1065e847e6262ccd29"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/tsv-zorneding-fussball/tsv-zorneding-fussball/plugins/gatsby-theme-tsv-zorneding/cms/cms.js","manualInit":true,"htmlTitle":"Administration TSV Zorneding 1920 e.V. - Abteilung Fussball","htmlFavicon":"/home/runner/work/tsv-zorneding-fussball/tsv-zorneding-fussball/plugins/gatsby-theme-tsv-zorneding/img/favicon-32x32.png","enableIdentityWidget":false},
    },{
      plugin: require('../plugins/gatsby-theme-tsv-zorneding/gatsby-browser.js'),
      options: {"plugins":[],"ENV":"production","title":"TSV Zorneding 1920 e.V. - Abteilung Fussball"},
    }]
